<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{title}}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
            <CCol md="12">
              <div role="group" class="form-group">
                <label>OPD</label>
                <VueSelect
                placeholder="Nama OPD"
                v-model="form.nama_unit_kerja"
                :options="optionsOPD"
                label="nama_opd"
                :reduce="x => x.nama_opd"
                :filterable="true"
                @search="onSearchOPD"
                ></VueSelect>
              </div>
            </CCol>
        </CRow>
        <CRow v-show="!formVisibility">
          <CCol md="2">
            <CSelect
              label="Bulan Usulan"
              :options="optionsMonth"
              placeholder="Pilih Bulan"
              :value.sync="form.bulan"
            />
          </CCol>
          <CCol md="2">
            <CSelect
              label="Tahun Usulan"
              :options="year"
              placeholder="Pilih Tahun"
              :value.sync="form.tahun"
            />
          </CCol>
        </CRow>
        <CRow v-show="!formVisibility">
          <CCol md="4">
            <CSelect
              label="Golongan"
              :options="[{value: null, label: 'Pilih Golongan'}, {value: 1, label: 'Gol I s.d. Gol IV/a'}, {value: 2, label: 'Gol IV/b ke atas'}]"
              placeholder="Pilih Golongan"
              :value.sync="form.golongan"
            />
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <!-- <form-input @done="resetTabel" @clicked="onClickChild" ref="formInput" /> -->
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
        <CRow style="float: right; padding-right: 16px;">
          <CButton
            color="success"
            square
            class="ml-1 mt-1"
            @click="printRecap()"
            :disabled="items.length <= 0"
            >Cetak</CButton
          >
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
  import { VueSelect } from "vue-select";
  import _ from "lodash";
  const fields = [
    { key: "number", label: "No" },
    { key: "nama", label: "Nama" },
    { key: "tempat_lahir", label: "Tempat Lahir" },
    { key: "tgl_lahir", label: "Tanggal Lahir" },
    { key: "nip", label: "NIP" },
    { key: "pangkat", label: "Pangkat" },
    { key: "golongan", label: "Golongan" },
    { key: "nama_jabatan", label: "Jabatan" },
    { key: "nama_unit_kerja", label: "Unit Kerja" },
    { key: "nama_layanan", label: "Jenis Pensiun" },
  ];
  export default {
    name: "RekapitulasiPenerbitanSKPensiun",
    components: {
      VueSelect
    },
    data() {
      return {
        items: [],
        fields,
        details: [],
        collapseDuration: 0,
        formVisibility: false,
        loading: false,
        page: 1,
        totalPage: 0,
        optionsInstansi: [],
        optionsUnitKerja: [],
        optionsOPD: [],
        optionsJabatanUmum: [],
        optionsMonth: [
          {
            value: null,
            label: 'Pilih Bulan'
          },
          {
            value: '01',
            label: 'Januari'
          },
          {
            value: '02',
            label: 'Februari'
          },
          {
            value: '03',
            label: 'Maret'
          },
          {
            value: '04',
            label: 'April'
          },
          {
            value: '05',
            label: 'Mei'
          },
          {
            value: '06',
            label: 'Juni'
          },
          {
            value: '07',
            label: 'Juli'
          },
          {
            value: '08',
            label: 'Agustus'
          },
          {
            value: '09',
            label: 'September'
          },
          {
            value: '10',
            label: 'Oktober'
          },
          {
            value: '11',
            label: 'November'
          },
          {
            value: '12',
            label: 'Desember'
          }
        ],
        form: {
          nama_unit_kerja: '',
          bulan: '',
          tahun: '',
          golongan: ''
        },
        status_layanan: null
      };
    },
    watch: {
      page: function () {
        this.loadData();
      }
    },
    computed: {
      computedItems() {
        var number = 1;
        let total_pages = 10 * (this.page - 1);
        return this.items.map((item) => {
          return {
            ...item,
            number: number++ + total_pages,
          };
        });
      },
      title() {
        return this.formVisibility
          ? "Form Rekapitulasi Usulan Penerbitan SK Pensiun"
          : "Tabel Rekapitulasi Usulan Penerbitan SK Pensiun";
      },
      year() {
        const d = new Date();
        var optionsYear = [
          {
            value: null,
            label: 'Pilih Tahun'
          }
        ]
        for (let i = parseInt(d.getFullYear()); i > (parseInt(d.getFullYear())-5); i--) {
          let obj = {
            value: i,
            label: i.toString()
          }
          optionsYear.push(obj)
        }
        return optionsYear
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      reset() {
        this.form.nama_unit_kerja = '';
        this.form.bulan = '';
        this.form.tahun = '';
        this.form.golongan = '';
        this.page = 1;
        this.loadData();
      },
      async loadData() {
        try {
          this.loading = true;
          let data = await this.$store.dispatch("pelayanan/indexRekapitulasiPenerbitanSkPensiun", {
            page: this.page,
            data: this.form,
          });
          this.items = data.data;
          this.totalPage = data.last_page;
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      resetTabel() {
        // this.$refs.formInput.populateInput(null);
        this.formVisibility = false;
        this.loadData();
      },
      toggleDetails(item, formDisabled) {
        this.formVisibility = true;
        // this.$refs.formInput.populateInput(item, formDisabled);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      },
      openNewForm() {
        this.formVisibility = !this.formVisibility;
        // this.$refs.formInput.populateInput(null);
      },
      onClickChild() {
        this.formVisibility = !this.formVisibility;
        // this.$refs.formInput.populateInput(null);
      },
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
      viewTracking(item) {
        item.modalTitle = 'Penerbitan SK Pensiun'; // please change this according to each menu
        this.$refs.modalViewTracking.toggle(item);
      },
      printRecap() {
        window.open(
        "https://api-layanan.pojokaparatur.purwakartakab.go.id" +
        // "https://dev.api-layanan.pojokaparatur.purwakartakab.go.id" +
        // "http://127.0.0.1:8001" +
            "/api/pelayanan/cetak-rekapitulasi-penerbitan-sk-pensiun?nama_unit_kerja=" +
            this.form.nama_unit_kerja
            + '&bulan=' + this.form.bulan
            + '&tahun=' + this.form.tahun
            + '&golongan=' + this.form.golongan,
          "_blank"
        );
      },
    },
  };
</script>